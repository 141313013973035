<template>
  <div class="view pa24">
    <div>
      订单状态：
      <el-radio v-model="radioValue" class="mr10" style="margin: 0" label="" border size="medium">全部订单</el-radio>
      <el-radio v-model="radioValue" class="mr10" style="margin: 0" label="1" border size="medium">待付款</el-radio>
      <el-radio v-model="radioValue" class="mr10" style="margin: 0" label="2" border size="medium">待发货</el-radio>
      <el-radio v-model="radioValue" class="mr10" style="margin: 0" label="3" border size="medium">待收货</el-radio>
      <el-radio v-model="radioValue" class="mr10" style="margin: 0" label="4" border size="medium">交易完成</el-radio>
      <div style="float: left;margin-right: 10px;">
        订单编号:
        <el-input placeholder="请输入订单编号" v-model="ordersNo" style="width:230px;"></el-input>
      </div>
      <el-button type="primary" @click="searchFun">搜索</el-button>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column prop="ordersNo" align="center" label="订单编号" width="200px" show-overflow-tooltip />
        <el-table-column prop="name" align="center" label="分销人昵称">
          <template slot-scope="scope">
            {{ scope.row.name ? scope.row.name : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="分销人手机号">
          <template slot-scope="scope">
            {{ scope.row.phone ? scope.row.phone : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" />
        <el-table-column prop="allPrice" align="center" label="实付金额（元）">
          <template slot-scope="scope">
            {{
        scope.row.allPrice == 0
          ? 0
          : (scope.row.allPrice / 100).toFixed(2)
      }}
          </template>
        </el-table-column>
        <el-table-column prop="royaltyRatio" align="center" label="分销提成（元）">
          <template slot-scope="scope">
            {{
          scope.row.royaltyRatio
            ? scope.row.royaltyRatio.toFixed(2)
            : 0
        }}
          </template>
        </el-table-column>
        <el-table-column prop="level" align="center" label="分销等级">
          <template slot-scope="scope">
            {{ scope.row.level | level2str }}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" show-overflow-tooltip label="付款时间">
          <template slot-scope="scope">
            {{ scope.row.payTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="payName" align="center" label="付款人姓名" />
        <el-table-column prop="payPhone" align="center" label="联系方式" />
        <el-table-column prop="locationAddress" align="center" show-overflow-tooltip label="收货地址" />
        <el-table-column align="center" label="订单状态">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.refundState | state2str(scope.row.orderState) }}</el-tag>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { selectDistributorOrderList } from "@/api/distribution";
export default {
  name: "distributionOrder",
  components: {
    commonTable,
  },
  data() {
    return {
      radioValue: "",
      ordersNo: '',
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  filters: {
    level2str(level = 0) {
      let obj = {
        0: "-",
        1: "一级分销",
        2: "二级分销",
        3: "三级分销",
      };
      return obj[level];
    },
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
    state2str(refundState, state) {
      let obj = {
        1: {
          0: "-",
          1: "待付款",
          2: "待发货",
          3: "待收货",
          4: "已完成",
          5: "取消订单",
          6: "退款成功",
        },
        2: "退款中",
        3: "退款成功",
      };

      return refundState == 1 ? obj[refundState][state] : obj[refundState];
    },
  },
  created() {
    this.selectDistributorOrderList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectDistributorOrderList();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.selectDistributorOrderList();
    },
    searchFun() {
      this.currentPage = 1
      this.selectDistributorOrderList();
    },
    async selectDistributorOrderList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        orderState: this.radioValue,
        ordersNo: this.ordersNo
      };
      try {
        this.loading = true;
        const result = await selectDistributorOrderList(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.tableData.forEach(item => {
          if (item.royaltyRatio)
            item.royaltyRatio = item.royaltyRatio - 0
        });
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
        //-console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
